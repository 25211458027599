import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IForm, IFormState } from '../../../@types/@acs/form';
import axios from '../../../utils/axios';
import axios_s3 from 'axios';

// ----------------------------------------------------------------------

const initialState: IFormState = {
    isLoading: false,
    error: null,
    forms: [],
    form: null,
    formFile: null,
};

const slice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.forms = initialState.forms;
            state.form = initialState.form;
            state.formFile = initialState.formFile;
        },

        // GET Forms
        getFormsSuccess(state, action) {
            state.isLoading = false;
            state.forms = action.payload;
        },

        // DOWNLOAD FORM SUCCESS
        // downloadFormSuccess(state) {
        //     state.isLoading = false;
        // },

        // ADD FORM SUCCESS
        addFormSuccess(state) {
            state.isLoading = false;
        },

        // UPDATE FORM SUCCESS
        updateFormSuccess(state) {
            state.isLoading = false;
        },

        // DELETE FORM SUCCESS
        deleteFormSuccess(state) {
            state.isLoading = false;
        }, 

        // SET MANAGE FORM
        setFormManage(state, action) {
            state.form = action.payload;
        },

        // SET FORM FILE
        setFormFile(state, action) {
            state.formFile = action.payload;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize,
    setFormManage,
    setFormFile
} = slice.actions;

export function getForms() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const forms = await axios.get(`/acs/forms`);

            const list: IForm[] = forms.data.map((data: any) => {
                return {
                    id: data.id,
                    name: data.name,
                    ossObject: data.ossObject,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt,
                } as IForm;
            });

            dispatch(slice.actions.getFormsSuccess(list));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function downloadForm(form: IForm) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const fileExtension = form.ossObject.objectKey.split('.').pop();

            const response = await axios.get(`/aps/o/oss/v2/buckets/form.bimlize.cloud/objects/${form.ossObject.objectKey}/signeds3download?fileName=${form.name}.${fileExtension}`);
            // window.open(response.data.url, '_self');

            if (fileExtension === 'pdf') {
                dispatch(slice.actions.setFormFile(response.data.url));
                return response.data.url;
            } else if (fileExtension === 'rdlx-json') {
                await fetch(response.data.url)
                    .then(response => response.text())
                    .then(textData => {
                        const blob = new Blob([textData], { type: 'text/plain;charset=utf-8' });
                        const blobURL = URL.createObjectURL(blob);
                        dispatch(slice.actions.setFormFile(blobURL));
                    })
                    .catch(error => {
                        console.error('다운로드 및 변환 중 오류 발생: ', error);
                        dispatch(slice.actions.setFormFile(null));
                    });
            } else {
                throw new Error(`Unsupported file extension: ${fileExtension}`);
            }

            // dispatch(slice.actions.downloadFormSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function addForm(file: any/*IFileForm*/) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const fileExtension = file.path.split('.').pop();

            // 1. form
            const name = file.name.replace(/\.[^.]+$/, "");
            const response_1 = await axios.post(`/acs/forms`, { name: name });
            const id = response_1.data.id;

            // 2. start upload
            const response_2 = await axios.get(`/aps/o/oss/v2/buckets/form.bimlize.cloud/objects/${id}.${fileExtension}/signeds3upload`);
            const uploadKey = response_2.data.uploadKey;
            const url = response_2.data.urls[0];
            
            // 3. upload to s3 (no Auth)
            const response_3 = await axios_s3.put(url, file.binaryData, {
                headers: {
                  'Content-Type': 'application/octet-stream', // 형식을 binary로 설정
                },
            });

            // 4. end upload
            const response_4 = await axios.post(`/aps/o/oss/v2/buckets/form.bimlize.cloud/objects/${id}.${fileExtension}/signeds3upload`, { uploadKey: uploadKey });
            const objectKey = response_4.data.objectKey;

            // 5. form update upload
            const response_5 = await axios.post(`/acs/forms/${id}/upload`, { objectKey: objectKey });
            
            dispatch(slice.actions.addFormSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function updateForm(formId: string, name: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            await axios.patch(`/acs/forms/${formId}`, { name: name });

            dispatch(slice.actions.updateFormSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function deleteForm(formId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            await axios.delete(`/acs/forms/${formId}`);

            dispatch(slice.actions.deleteFormSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};