import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IForecastState, WeatherData, ForecastData, weatherDescKo } from '../../../@types/@acs/forecast';
// import axios from '../../../utils/axios';
import axios from 'axios';
import config from 'configs/config';

// ----------------------------------------------------------------------

const initialState: IForecastState = {
    isLoading: false,
    error: null,
    weather: null,
    forecast: null,
};

const slice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.weather = initialState.weather;
            state.forecast = initialState.forecast;
        },

        // GET Weather
        getWeatherSuccess(state, action) {
            state.isLoading = false;
            state.weather = action.payload;
        },

        // GET Forecast
        getForecastSuccess(state, action) {
            state.isLoading = false;
            state.forecast = action.payload;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize,
} = slice.actions;

// 위치 정보를 가져오는 함수를 Promise로 감싸기
function getPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

export function getCurrentWeather() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            // navigator.geolocation.getCurrentPosition((position) => {
            //     let lat = position.coords.latitude;   // 경도
            //     let lon = position.coords.longitude;  // 위도
            // });
            const position = await getPosition();
            const latitude = position.coords.latitude.toString();
            const longitude = position.coords.longitude.toString();

            const appId = config.openWeather.apiKey;

            // const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=Seoul&appid=${appId}&units=metric&lang=kr`);
            // lang을 kor로 해도 번역 이상하니까 id로 한글 define된거 가져오기
            const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${appId}&units=metric&lang=kr`);

            // 지역
            const area = response.data.name;

            // 오늘 날짜
            const today = new Date();
            const month = today.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더해줍니다.
            const date = today.getDate();
            const dayOfWeek = today.getDay(); // getDay()는 요일을 숫자로 반환합니다. 0(일요일)부터 6(토요일)
            const dayOfWeekStr = ["일", "월", "화", "수", "목", "금", "토"][dayOfWeek]; // 요일 문자열 배열
            // 월과 일을 두 자리 숫자로 포맷팅합니다.
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;
            const formattedDate = date < 10 ? `0${date}` : `${date}`;
            // 최종 날짜 문자열을 mm-dd(d) 형태로 생성합니다.
            const formattedToday = `${formattedMonth}-${formattedDate}(${dayOfWeekStr})`;

            // // id 찾아서 매칭 후 description 한글 번역된 거 가져오기
            const weatherId = response.data.weather[0].id;
            const description = weatherDescKo[weatherId];

            // 온도(반올림하여 소수점 버리기)
            const temp = Math.round(response.data.main.temp);
            const maxTemp = Math.round(response.data.main.temp_max);
            const minTemp = Math.round(response.data.main.temp_min);

            // 날씨 아이콘 가져오기
            const weatherIcon = response.data.weather[0].icon;
            // 고해상도 아이콘 URL
            const iconUrl = `http://openweathermap.org/img/wn/${weatherIcon}@2x.png`;
            // 포준해상도 아이콘 URL
            // const iconUrl = `http://openweathermap.org/img/wn/${response.data.weather[0].icon}.png`;


            // const weathers = response.data.weather[response.data.weather.length - 1];

            const weather: WeatherData = {
                area: area,
                date: formattedToday,
                description: description,
                temp: temp,
                maxTemp: maxTemp,
                minTemp: minTemp,
                weatherIconUrl: iconUrl,
            };

            dispatch(slice.actions.getWeatherSuccess(weather));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getFiveDayWeatherForecast() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const position = await getPosition();
            const latitude = position.coords.latitude.toString();
            const longitude = position.coords.longitude.toString();

            const appId = config.openWeather.apiKey;

            const response = await axios.get(`https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${appId}&units=metric&lang=kr`);

            // 데이터를 가공하여 요일별로 정리
            const forecastList = response.data.list;
            const dailyForecasts: { [key: string]: ForecastData } = {};

            // 오늘 날짜 가져오기
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // 5일 후 날짜 계산
            const futureDate = new Date(today);
            futureDate.setDate(today.getDate() + 5);

            // 3시간 단위로 예보 데이터를 가공
            forecastList.forEach((item: any) => {
                const date = new Date(item.dt_txt);
                if (date >= today && date < futureDate) {
                    // const day = date.toLocaleDateString('ko-KR', { weekday: 'short' });
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const dayOfMonth = String(date.getDate()).padStart(2, '0');
                    // const formattedDate = `${month}-${dayOfMonth}(${day})`;
                    const formattedDate = `${year}-${month}-${dayOfMonth}`;
                    // const temp = item.main.temp;
                    const weatherId = item.weather[0].id;
                    const description = weatherDescKo[weatherId];
                    const weatherIcon = item.weather[0].icon;
                    const weatherIconUrl = `http://openweathermap.org/img/wn/${weatherIcon}@2x.png`;

                    if (!dailyForecasts[formattedDate]) {
                        dailyForecasts[formattedDate] = {
                            area: response.data.city.name,
                            date: formattedDate,
                            description: description,
                            maxTemp: item.main.temp_max,
                            minTemp: item.main.temp_min,
                            weatherIconUrl,
                            icons: [weatherIcon]
                        };
                    } else {
                        dailyForecasts[formattedDate].maxTemp = Math.max(dailyForecasts[formattedDate].maxTemp, item.main.temp_max);
                        dailyForecasts[formattedDate].minTemp = Math.min(dailyForecasts[formattedDate].minTemp, item.main.temp_min);
                        dailyForecasts[formattedDate].icons?.push(weatherIcon);
                    }
                }
            });

            // 가장 빈도가 높은 아이콘을 대표 아이콘으로 선정
            Object.values(dailyForecasts).forEach(forecast => {
                if (forecast.icons) {
                    // 아이콘 빈도 계산
                    const iconFrequency = forecast.icons.reduce((acc: { [key: string]: number }, icon) => {
                        acc[icon] = (acc[icon] || 0) + 1;
                        return acc;
                    }, {});
            
                    // 가장 빈도가 높은 아이콘 선택
                    const mostFrequentIcon = Object.keys(iconFrequency).reduce((a, b) => iconFrequency[a] > iconFrequency[b] ? a : b);
                    
                    // 대표 아이콘 URL 설정
                    // forecast.weatherIconUrl = `http://openweathermap.org/img/wn/${mostFrequentIcon}@2x.png`;
                    // 대표 아이콘이 Night인 경우, Day로 변경하여 e대표 아이콘 URL 설정
                    const modifiedIcon = mostFrequentIcon.replace('n', 'd');
                    forecast.weatherIconUrl = `http://openweathermap.org/img/wn/${modifiedIcon}@2x.png`;
            
                    // 아이콘 목록 삭제 (불필요한 데이터 제거)
                    delete forecast.icons;
                }
            });

            // 객체를 배열로 변환
            const forecastDataArray = Object.values(dailyForecasts);

            dispatch(slice.actions.getForecastSuccess(forecastDataArray as ForecastData[]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};