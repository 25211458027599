import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { DefineAttributes, IAttachment, IIssue, IIssueState, IUrnItem } from '../../../@types/@acs/issue';
import axios from '../../../utils/axios';
import { IProject } from '../../../@types/@acs/project';
import { RootState } from 'redux/store';


// ----------------------------------------------------------------------

const initialState: IIssueState = {
    isLoading: false,
    error: null,
    issues: [],
    issue: null,
    attachments: [],
    viewerToken: '',
    viewUrn: '',
    reportImages: [],
    hasToDoType: false,
};

const slice = createSlice({
    name: 'issue',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.issue = null;
        },

         // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.issues = initialState.issues;
            state.issue = initialState.issue;
            state.attachments = initialState.attachments;
            state.viewerToken = initialState.viewerToken;
            state.viewUrn = initialState.viewUrn;
            state.reportImages = initialState.reportImages;
        },

        // GET ISSUES
        getIssuesSuccess(state, action) {
            state.issues = action.payload;
            state.isLoading = false;
        },

        // GET ISSUE CUSTOM DATA
        getIssueCustomDataSuccess(state, action) {
            state.issue = action.payload;
            state.isLoading = false;
        },

        // GET ISSUE ATTACHMENT
        getIssueAttachmentSuccess(state, action) {
            state.attachments = action.payload;
            state.isLoading = false;
        },

        // SET SELECT ISSUE MANAGE
        setIssueManage(state, action) {
            const issue = state.issues.find(m => m.id === action.payload);
            state.issue = (issue) ? issue : null;
            
            // state.issue = action.payload;
            state.isLoading = false;
        },

        // SET ISSUE REPORT IMAGES
        setIssueReportImages(state, action) {
            state.reportImages = action.payload;
            state.isLoading = false;
        },

        // SET END LOADING (GET DOWNLOAD ISSUE FILE)
        setEndLoading(state) { //getDownloadIssueFileSuccess(state) {
            state.isLoading = false;
        },

        // GET ISSUE VIEWER TOKEN
        getIssueViewerTokenSuccess(state, action) {
            state.viewerToken = action.payload;
            state.isLoading = false;
        },

        // GET ISSUE URN
        getIssueUrnSuccess(state, action) {
            state.viewUrn = action.payload;
            state.isLoading = false;
        },

        // SET ISSUE FAVORITE STATUS
        setIssueStatus(state, action) {
            const { id, isFavorite } = action.payload;

            state.issues = state.issues.map((issue) => {
                if (issue.id === id) {
                    return {
                        ...issue,
                        isFavorite,
                    }
                }

                return issue;
            });
        },

        // SET TODO TYPE EXISTED
        setHasToDoType(state, action) {
            state.hasToDoType = action.payload;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    initialize,
    getIssuesSuccess,
    setIssueManage,
    setIssueReportImages,
    getIssueCustomDataSuccess,
    getIssueAttachmentSuccess,
    setEndLoading,
} = slice.actions;

export function getIssues(project: IProject, isForce: boolean = false) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            /// 헤더 설정
            axios.defaults.headers.common['acs-hub-id'] = `${project.hubId}`;
            axios.defaults.headers.common['acs-container-id'] = `${project.containerId}`;
            axios.defaults.headers.common['acs-project-id'] = `${project.id}`;
            axios.defaults.headers.common['acs-project-type'] = `${project.type}`;


            /// DB에 Issue ID 등록 여부 확인 (이슈 즐겨찾기) -- userId는 Server가 Token을 보고 내부적으로 처리
            const issues = await axios.get(`/acs/projects/${project.id}/issues?force=true`);
            const existingIssues = issues.data.map((item: any) => item);


            let issueResponse;
            if (isForce) {
                issueResponse = await axios.get(`/aps/w/projects/${project.id}/issues?force=true`); // 동일 사용 : ?bypass=true
            } else {
                issueResponse = await axios.get(`/aps/w/projects/${project.id}/issues`);    // ?force=true는 원래 없음
            }
            // const issueResponse = await axios.get(`/aps/w/projects/${project.id}/issues?force=true`);
            const issueTypeResponse = await axios.get(`/aps/w/projects/${project.id}/issue-types?force=true`);
            const todoItem = issueTypeResponse.data.results.find((item: any) => item.title.toLowerCase() === "todo");
            let todoSubtype: any;
            if (todoItem) {
                todoSubtype = todoItem.subtypes.find((subtype: any) => subtype.title.toLowerCase() === "todo");
            }

            /// > > > 전체 User
            // const hubId = project.hubId.startsWith('b.') ? project.hubId.substring(2) : project.hubId;
            // const allUsers = await axios.get(`/aps/o/hq/v1/accounts/${hubId}/users?force=true`);
            // // console.log(allUsers.data);
            // const foundUser = allUsers.data.find((user: { uid: string; }) => user.uid === 'S44276SVR7QWCXXE');

            /// > > > 프로젝트에 포함된 User 전체
            const users = await axios.get(`/aps/o/construction/admin/v1/projects/${project.id}/users?force=true`);
            // console.log(users.data.results);

            /// > > > 개별 User
            // const users2: any[] = await Promise.all(users.data.results.map(async (data: any) => {
            //     const response = await axios.get(`/aps/o/construction/admin/v1/projects/${project.id}/users/${data.autodeskId}`);   // 'data.id' OR 'autodeskId'
            //     return response.data;
            // }));
            // console.log(users2);

            /// > > > 정의된 커스텀 데이터 항목 전체
            // const definitions = await axios.get(`/aps/w/projects/${project.id}/issue-attribute-definitions`);

            const list: IIssue[] = await Promise.all(issueResponse.data.results
                // .filter((data: any) => data.issueSubtypeId !== todoSubtype.id)  /// > > >  ToDo는 이슈 목록에서 제외
                /// > > >  ToDo는 이슈 목록에서 제외
                .filter((data: any) => {
                    if (todoItem && todoSubtype) {
                        return data.issueSubtypeId !== todoSubtype.id;
                    }
                    return true; // todoItem 또는 todoSubtype이 없으면 필터링하지 않고 모두 통과
                })
                .map(async (data: any) => {
                    let isFavorite;
                    const foundIssue = existingIssues.find((m: { issueId: string; }) => m.issueId === data.id);
                    if (!foundIssue || typeof foundIssue === 'undefined') {
                        isFavorite = false;
                        await axios.put(`/acs/projects/${project.id}/issues/${data.id}`, { isFavorite: isFavorite });
                    } else {
                        isFavorite = foundIssue.isFavorite;
                    }

                    const issueType = issueTypeResponse?.data.results?.find((m: { id: string; }) => m.id === data.issueTypeId);
                    const issueSubType = issueType.subtypes?.find((m: { id: string; }) => m.id === data.issueSubtypeId);

                    let linkedDocument: any;
                    if (data.linkedDocuments && data.linkedDocuments.length > 0) {
                        const urn = data.linkedDocuments[0].urn;
                        const response = await axios.get(`/aps/o/data/v1/projects/${project.id}/items/${urn}?force=true`);
                        linkedDocument = {
                            displayName: response.data.data.attributes.displayName,
                            hidden: response.data.data.attributes.hidden,
                        }
                    }

                    /// > > > assignedTo 찾을 수 없는 경우
                    /// BIM360 : 프로젝트에서 이전에 포함되어 있다가 삭제된 Assigned to(user)는 조회되지 않으므로 찾을 수 없음
                    /// ACC : 할당 대상이 프로젝트 내 구성원이 아닌 역할 또는 회사 일 경우 조회되지 않으므로 찾을 수 없음
                    const user = users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === data.assignedTo);
                    const owner = users?.data.results?.find((m: { autodeskId: string; }) => m.autodeskId === data.ownerId);

                    const foundUserName = users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === data.createdBy)?.name || undefined;
                    const foundDate = new Date(data.createdAt).toISOString().split('T')[0];


                    // const watchers = data.watchers.map((id: string) => {
                    //     const user = users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === id).name;
                    //     return { id, value: user ? user : '' };
                    // });
                    const watchers = data.watchers
                        ? data.watchers
                            .map((id: string) => users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === id)?.name || '')
                            .join(', ')
                        : ''; // BIM360은 없는듯..


                    return {
                        id: data.id,
                        containerId: data.containerId,
                        deleted: data.deleted,
                        displayId: data.displayId,
                        title: data.title,
                        description: data.description,
                        issueType: issueType,
                        issueSubtype: issueSubType,
                        status: data.status,
                        assignedTo: user,
                        assignedToType: user?.companyName,
                        dueDate: data.dueDate,
                        startDate: data.startDate,
                        owner: owner,
                        rootCauseId: data.rootCauseId,
                        published: data.published,
                        commentCount: data.commentCount,
                        attachmentCount: data.attachmentCount,
                        openedBy: data.openedBy,
                        openedAt: data.openedAt,
                        closedBy: data.closedBy,
                        closedAt: data.closedAt,
                        createdBy: foundUserName,
                        createdAt: foundDate,
                        updatedBy: data.updatedBy,
                        updatedAt: data.updatedAt,
                        watchers: watchers, //data.watchers,
                        linkedDocuments: data.linkedDocuments,  // data.linkedDocuments.at(0)
                        customAttributes: data.customAttributes,
                        linkedDocument: linkedDocument,
                        isFavorite: isFavorite,
                        locationId: data.locationId,
                        // defineAttributes: customAttributeResults,
                        // comments: commentResults,
                        // location: location,
                        locationDetail: data.locationDetails,
                    } as IIssue;
                }));

            dispatch(slice.actions.getIssuesSuccess(list));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function getIssueCustomData(projectId: string, issue: IIssue) {
    return async (dispatch: Dispatch) => {
        try {
            //dispatch(slice.actions.startLoading());

            /// > > > 정의된 커스텀 데이터 항목 전체
            const definitions = await axios.get(`/aps/w/projects/${projectId}/issue-attribute-definitions`);

            // const mappings = await axios.get(`/aps/w/projects/${project.id}/issue-attribute-mappings`);

            const customAttributeResults = issue.customAttributes
                .map((item: { type: string; attributeDefinitionId: any; title: any; value: any; }) => {
                    if (item.type === 'list') {
                        const result = definitions.data.results.find((result: { id: string; }) => result.id === item.attributeDefinitionId);
                        const option = result.metadata.list.options.find((option: { id: string; }) => option.id === item.value);
                        const value = option ? option.value : ''; // item.value가 있을 때만 값을 할당, 없으면 빈 문자열 할당
                        return { type: item.type, title: result.title, value: value };
                        //return result ? result : null;
                    } else {
                        return { type: item.type, title: item.title, value: item.value };
                        //return data; // 'list'가 아닌 경우 data 반환
                    }
                })
                .filter((value: { type: string; title: any; value: any; } | null) => value !== null);

            const users = await axios.get(`/aps/o/construction/admin/v1/projects/${projectId}/users?force=true`);
            const comments = await axios.get(`/aps/w/projects/${projectId}/issues/${issue.id}/comments`);
            const commentResults = await Promise.all(comments.data.results.map(async (item: any) => {
                // 정규표현식을 사용하여 매칭 -> {} 밖의 내용 추출
                const pattern = /\"id\": \"(.*?)\"[^}]*\}(.*)$/;
                const match = item.body.match(pattern);
                const createdByUser = users?.data.results?.find((m: { autodeskId: string; }) => m.autodeskId === item.createdBy);
                const createBy = createdByUser ? createdByUser.name : null;
                let tagBy = null;
                let content = null;
                const date = new Date(item.createdAt).toLocaleString('ko-KR');
                if (match) {
                    const taggedUser = users?.data.results?.find((m: { autodeskId: string; }) => m.autodeskId === match[1]);
                    tagBy = taggedUser ? taggedUser.name : null;
                    content = match[2].trim();
                } else {
                    content = item.body;
                }
                return {
                    tagBy: tagBy ? '@' + tagBy : null,
                    // tagBy: tagBy,
                    date: date,
                    createBy: createBy,
                    content: content
                };
            }));

            const type = axios.defaults.headers.common['acs-project-type'];
            let location = '';
            if (type === 'ACC') {
                const locations = await axios.get(`/acc/projects/${projectId}/locations`);
                const option = locations.data.results.find((location: { id: any; }) => location.id === issue?.locationId);
                location = option ? option.name : ''; // item.value가 있을 때만 값을 할당, 없으면 빈 문자열 할당
            }
            

            const modifiedIssue: IIssue = { ...issue } as IIssue;
            modifiedIssue.defineAttributes = customAttributeResults as DefineAttributes[];
            modifiedIssue.comments = commentResults;
            modifiedIssue.location = location;


            dispatch(slice.actions.getIssueCustomDataSuccess(modifiedIssue));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
};

async function imageDownloadUrlToBase64(imageUrl: string, fileName: string) {
    const bucketKey = 'wip.dm.prod'; // 고정
    const parts = imageUrl.split('/');
    const objectKey = parts[parts.length - 1];
    const response = await axios.get(`/aps/o/oss/v2/buckets/${bucketKey}/objects/${objectKey}/signeds3download?fileName=${fileName}`);
    return response.data.url;
}

export function getIssueAttachments(projectId: string, issueId: string) {
    return async (dispatch: Dispatch) => {
        try {
            //dispatch(slice.actions.startLoading());

            let response: any;
            response = await axios.get(`/aps/w/projects/${projectId}/issues/${issueId}/attachments?force=true`);

            // --------------------> 모든 유저를 뒤져봐도 안나오는 경우가 많음......
            // let hubId = axios.defaults.headers.common['acs-hub-id'] as string;
            // hubId = hubId.startsWith('b.') ? hubId.substring(2) : hubId;
            // const allUsers = await axios.get(`/aps/o/hq/v1/accounts/${hubId}/users?force=true`) as any;

            const type = axios.defaults.headers.common['acs-project-type'];
            if (type === 'ACC') {
                response = response.data.relationships;
            } else {
                response = response.data.results;
            }

            let list: IAttachment[] = await Promise.all(response.map(async (data: any) => {
                /// < < < ACC
                if (type === 'ACC') {
                    // const filteredData = data.entities.filter((item: { type: string; }) => item.type !== "issue");
                    const filteredData = data.entities.filter((item: { type: string; }) => {
                        return item.type === "photo" || item.type === "documentlineage";
                    });
                    const entity = filteredData[0];
                    if (!entity || typeof entity === 'undefined') {
                        return null;
                    }

                    let pushData = { name: '', createdAt: new Date(), createdBy: '', updatedAt: new Date(),
                                     updatedBy: '', signedUrls: {fileUrl: '', thumbnailUrl: '' }, reportImageUrl: ''};
                    // photo(참조-사진)
                    if (entity.type === 'photo') {
                        const response = await axios.get(`/aps/o/construction/photos/v1/projects/${projectId}/photos/${entity.id}?force=true`);

                        // const updatedBy = allUsers.find((user: { uid: any; }) => user.uid === response.data.updatedBy);

                        const fileName = `${response.data.title}.${response.data.fileExtension}`
                        pushData = { name: fileName, createdAt: response.data.createdAt, createdBy: response.data.createdBy, updatedAt: response.data.updatedAt,
                                     updatedBy: response.data.updatedBy, signedUrls: response.data.signedUrls, reportImageUrl: response.data.signedUrls.fileUrl };
                    }
                    // documentlineage(참조-파일) : 이미지 파일도 가능
                    else {
                        const response = await axios.get(`/aps/o/data/v1/projects/${projectId}/items/${entity.id}?force=true`);
                        const attributes = response.data.data.attributes;

                        /// API signeds3download 활용한 파일 다운로드 위한 전처리
                        const included = response.data.included;
                        const storage = included.find((item: { relationships: { storage: any; }; }) => item.relationships?.storage) || undefined;
                        const signedUrls = { fileUrl: storage.relationships.storage.data.id, thumbnailUrl: storage.relationships.thumbnails.data.id };

                        // const updatedBy = allUsers.find((user: { uid: any; }) => user.uid === attributes.lastModifiedUserId);

                        const reportImageUrl = await imageDownloadUrlToBase64(signedUrls.fileUrl, attributes.displayName);

                        pushData = { name: attributes.displayName, createdAt: attributes.createTime, createdBy: attributes.createUserId, updatedAt: attributes.lastModifiedTime,
                                     updatedBy: attributes.lastModifiedUserId, signedUrls: signedUrls, reportImageUrl: reportImageUrl };
                    }

                    return {
                        id: entity.id,
                        attachmentType: entity.type,
                        urnType: null,  // ACC는 dm, oss와 같이 구분할 수 있는 거 없음
                        name: pushData.name,
                        createdAt: pushData.createdAt,
                        createdBy: pushData.createdBy,
                        updatedAt: pushData.updatedAt,
                        updatedBy: pushData.updatedBy,
                        signedUrls: pushData.signedUrls,
                        reportImageUrl: pushData.reportImageUrl,
                    } as IAttachment;
                }
                /// < < < BIM360
                else {
                    // const updatedBy = allUsers.find((user: { uid: any; }) => user.uid === data.updatedBy);

                    let urn = data.urn;
                    if (data.urnType === 'dm') {
                        /// API signeds3download 활용한 파일 다운로드 위한 전처리
                        const response = await axios.get(`/aps/o/data/v1/projects/${projectId}/items/${data.urn}?force=true`);
                        const included = response.data.included;
                        const storage = included.find((item: { relationships: { storage: any; }; }) => item.relationships?.storage) || undefined;
                        if (storage !== undefined) {
                            urn = storage.relationships.storage.data.id;
                        } else {
                            // 허가되지 않은 파일이므로 attachments.map 시, return
                            // You do not have permissions to view this document. Ask owner for permission.
                            urn = null;
                        }
                    }

                    let reportImageUrl = '';
                    if (urn !== null) {
                        reportImageUrl = await imageDownloadUrlToBase64(urn, data.name);
                    }

                    return {
                        id: data.id,
                        attachmentType: data.attachmentType,
                        urnType: data.urnType,
                        name: data.name,
                        createdAt: data.createdAt,
                        createdBy: data.createdBy,
                        updatedAt: data.updatedAt,
                        updatedBy: data.updatedBy,
                        signedUrls: { fileUrl: urn, thumbnailUrl: null },
                        reportImageUrl: reportImageUrl,
                    } as IAttachment;
                }
            }));

            /// ACC에서 photo와 documentlineage가 아닌 애들 null 처리되었으므로 list에서 제거
            list = list.filter(item => item !== null);

            dispatch(slice.actions.getIssueAttachmentSuccess(list));

            return list;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function downloadIssueFile(projectId: string, issueId: string, file: IAttachment) {
    return async (dispatch: Dispatch) => {
        try {
            //dispatch(slice.actions.startLoading());

            const type = axios.defaults.headers.common['acs-project-type'];
            const bucketKey = 'wip.dm.prod'; // 고정
            let isSigneds3download = false;

            if (type === 'ACC') {
                if (file.attachmentType === 'photo') {
                    const result = await fetch(file?.signedUrls.fileUrl);
                    const blob = await result.blob();
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.download = file.name;
                    link.href = url;
                    link.style.display = "none";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(url);
                } else if (file.attachmentType === 'documentlineage') { // ACC documentlineage 타입 파일 다운로드
                    isSigneds3download = true;
                } else {
                    throw new Error('첨부파일 타입 에러!');
                }
            } else { // BIM360 다운로드 (dm-document / dm-photo / oss-document / oss-photo 모두 동일)
                // oss : Upload from your 'computer'
                // dm : Upload from BIM360 'Project Files Folder'
                isSigneds3download = true;
            }

            // ACC documentlineage 타입 파일 다운로드 형식과, BIM360 다운로드 형식은 동일
            if (isSigneds3download) {
                const parts = file.signedUrls.fileUrl.split('/');
                const objectKey = parts[parts.length - 1];
                const response = await axios.get(`/aps/o/oss/v2/buckets/${bucketKey}/objects/${objectKey}/signeds3download?fileName=${file.name}`);
                window.open(response.data.url, '_self');
            }

            dispatch(slice.actions.setEndLoading());
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getReportPhotos(projectId: string, issueId: string) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            //dispatch(slice.actions.startLoading());

            const { issue } = getState();
            
            // photo인 파일 필터링 (photo가 아니어도 사진 파일인 경우 있으므로 참조/파일/urnType/attachmentType 에 상관 없이 확장자로 처리)
            // const filteredData = issue.attachments.filter((item: any) => {
            //     return item.attachmentType === "photo";
            // });
            const filteredImages = issue.attachments.filter((item: any) => {
                const fileExtension = item.name.split('.').pop();
                const imageExtensions = ["xbm", "tif", "pjp", "apng", "svgz", "jpg", "jpeg", "ico", "tiff", "gif", "svg", "jfif", "webp", "png", "bmp", "pjpeg", "avif",];
                const result = imageExtensions.includes(fileExtension.toLowerCase());
                return result;
            });

            // 이미지 다운로드 링크를 Blob화 한 후, Base64로 변환 및 prefix 제거
            const list: any[] = [];
            try {
                await Promise.all(filteredImages.map(async (item: any) => {
                    const response = await fetch(item.reportImageUrl);
                    if (!response.ok) {
                        throw new Error('이미지를 다운로드하는 중에 오류가 발생했습니다.');
                    }
                    const blob = await response.blob();
                    const base64data = await new Promise<string>((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            const result = reader.result as string;
                            const base64String = result.split(',')[1];
                            resolve(base64String);
                        };
                        reader.onerror = reject;
                    });
                    // list.push(base64data);
                    list.push({
                        imageToBase64: base64data,
                        name: item.name,
                        createdAt: item.createdAt
                        // ...item,
                    });
                }));
            } catch (error) {
                console.error(error);
            }

            dispatch(slice.actions.setIssueReportImages(list));


            // const reportImagesBase64 = await Promise.all(filteredImages.map(async (item: any) => {
            //     // console.log(item.reportImageUrl);

            //     await fetch(item.reportImageUrl)
            //         .then((response) => {
            //             if (!response.ok) {
            //                 throw new Error('이미지를 다운로드하는 중에 오류가 발생했습니다.');
            //             }
            //             return response.blob();
            //         })
            //         .then((blob) => {
            //             const reader = new FileReader();
            //             reader.readAsDataURL(blob);
            //             reader.onloadend = () => {
            //                 const base64data = reader.result as string;
            //                 const result = base64data.split(',')[1];
            //                 console.log(result);
            //                 return result;
            //             };
            //         })
            //         .catch((error) => {
            //             console.error(error);
            //         });
            // }));

            // console.log(reportImagesBase64)
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function getIssueViewerToken() {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.post(`/aps/w/auth/viewer-token`);
            dispatch(slice.actions.getIssueViewerTokenSuccess(response.data.access_token));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function getIssueUrn(projectId: string, linkedDocumentUrn: string) {
    return async (dispatch: Dispatch) => {
        try {
            let derivativesId: string | undefined;

            const response = await axios.get(`/aps/o/data/v1/projects/${projectId}/items/${linkedDocumentUrn}?force=true`);
            const urnItems = response.data.included;
            const derivative: IUrnItem | undefined = urnItems.find((item: { relationships: { derivatives: string; }; }) => item.relationships?.derivatives) || undefined;
            if (derivative === undefined) {
                const newestVersionId = response.data.data.relationships.tip.data.id;
                // const response_tip = await axios.get(`/aps/o/data/v1/projects/${projectId}/items/${linkedDocumentUrn}/tip?force=true`);
                // const newestVersionId = response_tip.data.data.id;
                const encodedVersionId = encodeURIComponent(newestVersionId);

                const response_ref = await axios.get(`/aps/o/data/v1/projects/${projectId}/versions/${encodedVersionId}/refs?force=true`);
                const refs = response_ref.data.data;
                const findedDerivative: IUrnItem | undefined = refs.find((item: { relationships: { derivatives: string; }; }) => item.relationships?.derivatives) || undefined;
                derivativesId = findedDerivative?.relationships?.derivatives?.data?.id;
            } else {
                derivativesId = derivative?.relationships?.derivatives?.data?.id;
            }

            dispatch(slice.actions.getIssueUrnSuccess(derivativesId));  // derivativesId === urnItems[0].id

            
            // const response = await axios.get(`/aps/o/data/v1/projects/${projectId}/items/${linkedDocumentUrn}?force=true`);
            // const urnItems = response.data.included;
            // const derivative: IUrnItem | undefined = urnItems.find((item: { relationships: { derivatives: string; }; }) => item.relationships?.derivatives) || undefined;
            // const derivativesId: string | undefined = derivative?.relationships?.derivatives?.data?.id;
            // dispatch(slice.actions.getIssueUrnSuccess(derivativesId));  // derivativesId === urnItems[0].id
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

// 이슈 즐겨찾기 설정
export function setIssueFavorite(id: string, isFavorite: boolean) {
    return async (dispatch: Dispatch) => {
        try {
            const projectId = axios.defaults.headers.common['acs-project-id'];
            await axios.put(`/acs/projects/${projectId}/issues/${id}`, {isFavorite: isFavorite});
            dispatch(slice.actions.setIssueStatus({ id, isFavorite }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 이슈 생성
export function createIssue(projectId: string, issueBody: any) {
    return async (dispatch: Dispatch) => {
        try {
            // console.log("Issue Body:", issueBody);
            const response = await axios.post(`/aps/w/projects/${projectId}/issues`, issueBody);
            if (response.status === 200) {
                if (response.data.reasonPhrase === "Created"){
                    // 성공
                    return;
                } else if (response.data.reasonPhrase === "Bad Request") {  //statusCode = "BadRequest"
                    throw new Error(response.data.reasonPhrase);
                } else {
                    throw new Error(response.data.reasonPhrase);
                }
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

// 이슈 수정
export function updateIssue(projectId: string, issueId: string, issueBody: any) {
    return async (dispatch: Dispatch) => {
        try {
            // console.log("Issue Body:", issueBody);
            const response = await axios.patch(`/aps/w/projects/${projectId}/issues/${issueId}`, issueBody);
            // console.log(response);
            if (response.status === 200) {
                // if (response.data.reasonPhrase === "Created"){
                //     // 성공
                //     return;
                // } else if (response.data.reasonPhrase === "Bad Request") {  //statusCode = "BadRequest"
                //     throw new Error(response.data.reasonPhrase);
                // } else {
                //     throw new Error(response.data.reasonPhrase);
                // }
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function addIssueAttachment(project: IProject, issueId: string, selectedFile: any) {
    return async (dispatch: Dispatch) => {
        try {
            let list: any[] = [];
            const response = await axios.get(`/aps/w/projects/${project.id}/issues/${issueId}/attachments?force=true`);

            if (project.type === 'ACC') {
                const relationships = response.data.relationships;

                list = await Promise.all(relationships.map(async (data: any) => {
                    const filteredData = data.entities.filter((item: { type: string; }) => {
                        return item.type === "photo" || item.type === "documentlineage";
                    });
                    const entity = filteredData[0];
                    if (!entity || typeof entity === 'undefined') {
                        return null;
                    }

                    return entity;
                }));

                const request: any = [
                    {
                        "entities": [
                            {
                                "domain": "autodesk-bim360-issue",
                                "type": "issue",
                                "id": issueId
                            },
                            {
                                // 파일 목록에서 가져오는 거 기준으로는 domain, type은 고정 (photo 추가 시에는 또 다름 - "autodesk-construction-photo", "photo")
                                "domain": "autodesk-bim360-documentmanagement",
                                "type": "documentlineage",
                                "id": selectedFile.id
                            }
                        ]
                    }
                ];

                const addResponse = await axios.put(`/aps/o/bim360/relationship/v2/containers/${project.containerId}/relationships`, request);
                if (addResponse.status === 200) {
                    // console.log(addResponse.data[0].entities);
                    return;
                } else {
                    throw new Error(addResponse.statusText);
                }
            } else {
                const result = response.data.results;

                list = await Promise.all(result.map(async (data: any) => {
                    return data;
                }));

                const request: any = {
                    // 파일 목록에서 가져오는 거 기준으로는 urnType은 고정 (로컬 파일을 업로드 시에는 "oss")
                    "name": selectedFile.attributes.displayName,
                    "urn": selectedFile.id,
                    "urnType": 'dm'
                };
                const addResponse = await axios.post(`/aps/w/projects/${project.id}/issues/${issueId}/attachments`, request);
                if (addResponse.status === 200) {
                    // console.log(addResponse.data);
                    return;
                } else {
                    throw new Error(addResponse.statusText);
                }
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function deleteIssueAttachment(containerId: string, issueId: string, selectedFiles: IAttachment[]) {
    return async (dispatch: Dispatch) => {
        try {
            const request: any = {
                "entities": [
                    {
                        "domain": "autodesk-bim360-issue",
                        "type": "issue",
                        "id": issueId
                    }
                ]
            };

            // 참조 검색
            const response = await axios.post(`/aps/o/bim360/relationship/v2/containers/${containerId}/relationships:intersect`, request);
            const relationships = response.data.relationships;
            
            // selectedFiles 배열에 있는 파일 객체의 id 값과 relationships 배열 내 entities 객체의 id 값이 일치하는 경우, 해당 relationships 객체의 id를 찾아서 반환
            const matchingRelationshipIds = relationships
                .filter((relationship: { entities: any[]; }) =>
                    relationship.entities.some(entity =>
                        selectedFiles.some(file => file.id === entity.id)
                    )
                )
                .map((relationship: { id: any; }) => relationship.id);

            // 참조 삭제
            const delResponse = await axios.post(`/aps/o/bim360/relationship/v2/containers/${containerId}/relationships:delete`, matchingRelationshipIds);
            if (delResponse.status === 200) {
                // console.log(delResponse.data.deleted);
                return;
            } else {
                throw new Error(delResponse.statusText);
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

async function getToDo(project: IProject, dispatch: Dispatch) {
    /// 헤더 설정
    axios.defaults.headers.common['acs-hub-id'] = `${project.hubId}`;
    axios.defaults.headers.common['acs-container-id'] = `${project.containerId}`;
    axios.defaults.headers.common['acs-project-id'] = `${project.id}`;
    axios.defaults.headers.common['acs-project-type'] = `${project.type}`;


    const issueTypeResponse = await axios.get(`/aps/w/projects/${project.id}/issue-types?force=true`);  // 동일 사용 : ?bypass=true
    const results = issueTypeResponse.data.results;
    const todoItem = results.find((item: any) => item.title.toLowerCase() === "todo");
    const hasValidSubtypes = todoItem?.subtypes.some(
        (subtype: { title: string; isActive: boolean; }) => subtype.title.toLowerCase() === 'todo' && subtype.isActive
    );
    if (!todoItem || !todoItem.isActive || todoItem.subtypes.length === 0 || !hasValidSubtypes) {
        // ToDo Type이 없거나, 비활성화된 경우, SubType이 없거나, 비활성화된 경우
        dispatch(slice.actions.setHasToDoType(false));
        dispatch(slice.actions.getIssuesSuccess([]));
        return;
    } else {
        // 활성화된 ToDo Type과 활성화된 SubType이 있는 경우
        dispatch(slice.actions.setHasToDoType(true));
    }

    const issueResponse = await axios.get(`/aps/w/projects/${project.id}/issues?issueTypeId=${todoItem.id}&force=true`);

    /// 프로젝트에 포함된 User 전체
    const users = await axios.get(`/aps/o/construction/admin/v1/projects/${project.id}/users?force=true`);

    /// 생성한 사람만이 해당 Todo(이슈)를 볼 수 있음 --> ACC 이슈에서 ToDo 만들고 게시 취소 안했을 경우에 대한 2차 필터링
    const response = await axios.get(`/aps/w/auth/me`);
    const user = response.data.sub;

    const list: IIssue[] = await Promise.all(issueResponse.data.results
        .filter((data: any) => data.createdBy === user) // 현재 사용자와 생성한 사람이 일치하는 경우의 ToDo만을 필터링
        .map(async (data: any) => {
            const issueType = issueTypeResponse?.data.results?.find((m: { id: string; }) => m.id === data.issueTypeId);
            const issueSubType = issueType.subtypes?.find((m: { id: string; }) => m.id === data.issueSubtypeId);

            /// > > > ToDo에서는 쓰이지 않으므로 속도 개선을 위해서 주석 처리
            // let linkedDocument: any;
            // if (data.linkedDocuments && data.linkedDocuments.length > 0) {
            //     const urn = data.linkedDocuments[0].urn;
            //     const response = await axios.get(`/aps/o/data/v1/projects/${project.id}/items/${urn}?force=true`);
            //     linkedDocument = {
            //         displayName: response.data.data.attributes.displayName,
            //         hidden: response.data.data.attributes.hidden,
            //     }
            // }

            /// > > > assignedTo 찾을 수 없는 경우
            /// BIM360 : 프로젝트에서 이전에 포함되어 있다가 삭제된 Assigned to(user)는 조회되지 않으므로 찾을 수 없음
            /// ACC : 할당 대상이 프로젝트 내 구성원이 아닌 역할 또는 회사 일 경우 조회되지 않으므로 찾을 수 없음
            const user = users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === data.assignedTo);
            const owner = users?.data.results?.find((m: { autodeskId: string; }) => m.autodeskId === data.ownerId);

            const foundUserName = users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === data.createdBy)?.name || undefined;
            const foundDate = new Date(data.createdAt).toISOString().split('T')[0];

            const watchers = data.watchers
                ? data.watchers
                    .map((id: string) => users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === id)?.name || '')
                    .join(', ')
                : '';

            return {
                id: data.id,
                containerId: data.containerId,
                deleted: data.deleted,
                displayId: data.displayId,
                title: data.title,
                description: data.description,
                issueType: issueType,
                issueSubtype: issueSubType,
                status: data.status,
                assignedTo: user,
                assignedToType: user?.companyName,
                dueDate: data.dueDate,
                startDate: data.startDate,
                owner: owner,
                rootCauseId: data.rootCauseId,
                published: data.published,
                commentCount: data.commentCount,
                attachmentCount: data.attachmentCount,
                openedBy: data.openedBy,
                openedAt: data.openedAt,
                closedBy: data.closedBy,
                closedAt: data.closedAt,
                createdBy: foundUserName,
                createdAt: foundDate,
                updatedBy: data.updatedBy,
                updatedAt: data.updatedAt,
                watchers: watchers,
                linkedDocuments: data.linkedDocuments,
                customAttributes: data.customAttributes,
                linkedDocument: {}, //linkedDocument,
                isFavorite: false,
                locationId: data.locationId,
                // defineAttributes: customAttributeResults,
                // comments: commentResults,
                // location: location,
                locationDetail: data.locationDetails,
            } as IIssue;
        }));

    return list;
}

export function getIssueToDo(project: IProject, isForce: boolean = false) {
    return async (dispatch: Dispatch) => {
        try {
            if (project.type === 'ACC') {
                dispatch(slice.actions.startLoading());

                const list = await getToDo(project, dispatch);
                if (list !== undefined) {
                    dispatch(slice.actions.getIssuesSuccess(list));
                } else {
                    //// ACC에서 ToDo가 없는 경우에는 list는 빈 배열 처리 필요
                    //// 이미 getToDo의 'ToDo Type이 없거나, 비활성화된 경우, SubType이 없거나, 비활성화된 경우'에서 처리 됨
                    // dispatch(slice.actions.getIssuesSuccess([]));
                }
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function updateToDo(project: IProject, issueId: string, issueBody: any) {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.patch(`/aps/w/projects/${project.id}/issues/${issueId}`, issueBody);
            if (response.status === 200) {
                if (response.data.reasonPhrase === "OK"){
                    // 성공
                    const list = await getToDo(project, dispatch);
                    dispatch(slice.actions.getIssuesSuccess(list));
                    return;
                } else if (response.data.reasonPhrase === "Bad Request") {
                    throw new Error(response.data.reasonPhrase);
                } else {
                    throw new Error(response.data.reasonPhrase);
                }
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function getSelectedFirstType(project: IProject) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            /// 헤더 설정
            axios.defaults.headers.common['acs-hub-id'] = `${project.hubId}`;
            axios.defaults.headers.common['acs-container-id'] = `${project.containerId}`;
            axios.defaults.headers.common['acs-project-id'] = `${project.id}`;
            axios.defaults.headers.common['acs-project-type'] = `${project.type}`;

            const issueTypeResponse = await axios.get(`/aps/w/projects/${project.id}/issue-types?force=true`);
            const results = issueTypeResponse.data.results;
            const issueType = results.find((item: { isActive: boolean; }) => item.isActive === true);   // isActive가 true인 첫 번째 값을 찾음
            return issueType.id;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

export function getSelectedTypeIssues(project: IProject, issueTypeId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            /// 헤더 설정
            axios.defaults.headers.common['acs-hub-id'] = `${project.hubId}`;
            axios.defaults.headers.common['acs-container-id'] = `${project.containerId}`;
            axios.defaults.headers.common['acs-project-id'] = `${project.id}`;
            axios.defaults.headers.common['acs-project-type'] = `${project.type}`;


            /// DB에 Issue ID 등록 여부 확인 (이슈 즐겨찾기) -- userId는 Server가 Token을 보고 내부적으로 처리
            const issues = await axios.get(`/acs/projects/${project.id}/issues?force=true`);
            const existingIssues = issues.data.map((item: any) => item);

            const issueTypeResponse = await axios.get(`/aps/w/projects/${project.id}/issue-types?force=true`);
            const issueResponse = await axios.get(`/aps/w/projects/${project.id}/issues?issueTypeId=${issueTypeId}&force=true`);
         

            /// > > > 프로젝트에 포함된 User 전체
            const users = await axios.get(`/aps/o/construction/admin/v1/projects/${project.id}/users?force=true`);

            const list: IIssue[] = await Promise.all(issueResponse.data.results
                .map(async (data: any) => {
                    let isFavorite;
                    const foundIssue = existingIssues.find((m: { issueId: string; }) => m.issueId === data.id);
                    if (!foundIssue || typeof foundIssue === 'undefined') {
                        isFavorite = false;
                        await axios.put(`/acs/projects/${project.id}/issues/${data.id}`, { isFavorite: isFavorite });
                    } else {
                        isFavorite = foundIssue.isFavorite;
                    }

                    const issueType = issueTypeResponse?.data.results?.find((m: { id: string; }) => m.id === data.issueTypeId);
                    const issueSubType = issueType.subtypes?.find((m: { id: string; }) => m.id === data.issueSubtypeId);

                    let linkedDocument: any;
                    if (data.linkedDocuments && data.linkedDocuments.length > 0) {
                        const urn = data.linkedDocuments[0].urn;
                        const response = await axios.get(`/aps/o/data/v1/projects/${project.id}/items/${urn}?force=true`);
                        linkedDocument = {
                            displayName: response.data.data.attributes.displayName,
                            hidden: response.data.data.attributes.hidden,
                        }
                    }

                    /// > > > assignedTo 찾을 수 없는 경우
                    /// BIM360 : 프로젝트에서 이전에 포함되어 있다가 삭제된 Assigned to(user)는 조회되지 않으므로 찾을 수 없음
                    /// ACC : 할당 대상이 프로젝트 내 구성원이 아닌 역할 또는 회사 일 경우 조회되지 않으므로 찾을 수 없음
                    const user = users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === data.assignedTo);
                    const owner = users?.data.results?.find((m: { autodeskId: string; }) => m.autodeskId === data.ownerId);

                    const foundUserName = users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === data.createdBy)?.name || undefined;
                    const foundDate = new Date(data.createdAt).toISOString().split('T')[0];

                    const watchers = data.watchers
                        ? data.watchers
                            .map((id: string) => users?.data.results?.find((m: { autodeskId: string }) => m.autodeskId === id)?.name || '')
                            .join(', ')
                        : ''; // BIM360은 없는듯..


                    return {
                        id: data.id,
                        containerId: data.containerId,
                        deleted: data.deleted,
                        displayId: data.displayId,
                        title: data.title,
                        description: data.description,
                        issueType: issueType,
                        issueSubtype: issueSubType,
                        status: data.status,
                        assignedTo: user,
                        assignedToType: user?.companyName,
                        dueDate: data.dueDate,
                        startDate: data.startDate,
                        owner: owner,
                        rootCauseId: data.rootCauseId,
                        published: data.published,
                        commentCount: data.commentCount,
                        attachmentCount: data.attachmentCount,
                        openedBy: data.openedBy,
                        openedAt: data.openedAt,
                        closedBy: data.closedBy,
                        closedAt: data.closedAt,
                        createdBy: foundUserName,
                        createdAt: foundDate,
                        updatedBy: data.updatedBy,
                        updatedAt: data.updatedAt,
                        watchers: watchers, //data.watchers,
                        linkedDocuments: data.linkedDocuments,  // data.linkedDocuments.at(0)
                        customAttributes: data.customAttributes,
                        linkedDocument: linkedDocument,
                        isFavorite: isFavorite,
                        locationId: data.locationId,
                        // defineAttributes: customAttributeResults,
                        // comments: commentResults,
                        // location: location,
                        locationDetail: data.locationDetails,
                    } as IIssue;
                }));

            dispatch(slice.actions.getIssuesSuccess(list));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};